$color_1: #A4A4A4;
$color_2: #000;
$background-color_1: #F8F8F8;
$background-color_2: #E77E6D;
$background-color_3: #91BAA4;
$border-color_1: #91BAA4;
$border-color_2: #fff;

.hh-grayBox {
	background-color: $background-color_1;
	margin-bottom: 20px;
	padding: 35px;
	margin-top: 20px;
}
.pt45 {
	padding-top: 45px;
}
.order-tracking {
	text-align: center;
	width: 25%;
	position: relative;
	display: inline-grid;
	.is-complete {
		display: block;
		position: relative;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		border: 0px solid #AFAFAF;
		background-color: $background-color_2;
		margin: 0 auto;
		transition: background 0.25s linear;
		-webkit-transition: background 0.25s linear;
		z-index: 2;
		&:after {
			display: block;
			position: absolute;
			content: '';
			height: 14px;
			width: 7px;
			top: -2px;
			bottom: 0;
			left: 5px;
			margin: auto 0;
			border: 0px solid #AFAFAF;
			border-width: 0px 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}
	}
	p {
		color: $color_1;
		font-size: 16px;
		margin-top: 8px;
		margin-bottom: 0;
		line-height: 20px;
		span {
			font-size: 14px;
		}
	}
	&::before {
		content: '';
		display: block;
		height: 3px;
		width: calc(100% - 40px);
		background-color: $background-color_2;
		top: 13px;
		position: absolute;
		left: calc(-50% + 20px);
		z-index: 0;
	}
	&:first-child {
		&:before {
			display: none;
		}
	}
}
.order-tracking.completed {
	.is-complete {
		border-color: $border-color_1;
		border-width: 0px;
		background-color: $background-color_3;
		&:after {
			border-color: $border-color_2;
			border-width: 0px 3px 3px 0;
			width: 7px;
			left: 11px;
			opacity: 1;
		}
	}
	p {
		color: $color_2;
	}
	&:before {
		background-color: $background-color_3;
	}
}
