.chatbot-container {
    position: relative;
    width: 400px;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.chatbot-open {
  	height: 500px;
}

.chatbot-closed {
  	height: 0;
}

.react-chatbot-kit-chat-container {
	width: 100%;
	height: auto;
}

.react-chatbot-kit-chat-bot-message {
  	margin-left: 0px;
	max-width: 100%;
	width: auto;
}

.react-chatbot-kit-user-chat-message {
    max-width: 100%;
}

